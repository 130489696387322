import { createI18n } from 'vue-i18n';
import Cookies from 'js-cookie';

import tw from './tw.json';
import cn from './cn.json';
import en from './en.json';
import es from './es.json';
import pt from './pt.json';
import vi from './vi.json';
import id from './id.json';
import ja from './ja.json';
import ko from './ko.json';
import tr from './tr.json';
import th from './th.json';
import hi from './hi.json';
import bn from './bn.json';
import ru from './ru.json';

let messages = {};
messages = { ...messages, tw, cn, en, es, pt, vi, id, ja, ko, tr, th, hi, bn, ru };

const lang = Cookies.get('lang') || 'cn';

const i18n = createI18n({
  legacy: false,
  locale: lang,
  messages,
});

export default i18n;
